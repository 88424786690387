import React, { useMemo } from 'react'
import { array, string, func, objectOf, bool } from 'prop-types'
import { Box, Typography, Button, useMediaQuery, styled } from '@mui/material'
import { useTest } from '@hooks/useTest'
import Ratings from '../../atoms/Ratings/Ratings'

const StyledReviewsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&>span': {
    width: 'auto',
    fontSize: 20,
    marginRight: 9.5,
    '.MuiRating-iconFilled': {
      color: `${theme.palette.primary.main}!important`,
    },
  },
  p: {
    color: theme.palette.primary.dark,
    fontSize: 12,
    '&:first-of-type': {
      fontWeight: 600,
      fontSize: 16,
      marginRight: 9.5,
    },
    '&.ratingCount': {
      color: `${theme.palette.grey[600]}!important`,
      fontSize: '14px',
      fontWeight: 500,
      margin: '3px 0 0 5px',
    },
    '&.readReviews': {
      marginTop: '2px',
    },
  },
  button: {
    '& > p': {
      color: `#757575 !important`,
      fontSize: '12px!important',
      fontWeight: 400,
      lineHeight: '15.84px',
      textTransform: 'capitalize',
      textDecoration: 'underline',
    },
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    button: {
      paddingLeft: '10px',
      paddingRight: '10px',
      minWidth: '0 !important',
    },
  },
}))

const StyledRatings = styled(Ratings)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '.MuiRating-decimal': {
      width: 'auto!important',
    },
  },
}))

const StoreReviewsShort = ({ reviewsData = {}, onReviewsClick, className, overallScore, showAverageText = true }) => {
  const { generateTestId } = useTest()
  const ratingsCount = reviewsData?.reviewsSource === 'product' ? reviewsData.ratingsCount : reviewsData.reviewsCount
  const isMobile = useMediaQuery(theme => theme?.breakpoints?.down('md'))

  const averageStars = useMemo(
    () =>
      (overallScore && parseFloat(overallScore).toFixed(1)) ||
      (reviewsData?.averageRating ??
        Math.round(
          (reviewsData?.reviewsItems?.reduce(
            (total, review) => total + Number(review?.starRating || review?.rating || 0),
            0,
          ) /
            ratingsCount) *
            10,
        ) / 10) ||
      0,
    [overallScore, ratingsCount, reviewsData],
  )

  if (averageStars <= 0)
    return (
      <Box
        sx={{
          height: '43px',
          '&:empty': {
            display: 'none',
          },
        }}
      />
    )

  return (
    <StyledReviewsContainer className={className}>
      {showAverageText && <Typography>{averageStars}</Typography>}
      <StyledRatings name="half-rating-read" readOnly max={5} precision={0.2} value={averageStars} />
      <Button onClick={onReviewsClick} data-testid={generateTestId('readreviews', 'button')}>
        {reviewsData?.reviewsSource === 'product' ? (
          <Typography className="readReviews">{`${ratingsCount} Reviews`}</Typography>
        ) : (
          <Typography>{isMobile ? `[${ratingsCount}]` : `${ratingsCount} Reviews`}</Typography>
        )}
      </Button>
    </StyledReviewsContainer>
  )
}

StoreReviewsShort.propTypes = {
  reviewsData: objectOf({
    reviewsItems: array,
  }),
  showAverageText: bool,
  onReviewsClick: func,
  className: string,
  overallScore: string,
}

export default StoreReviewsShort
